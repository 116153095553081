import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  Button,
  VStack,
  Text,
  HStack,
  Box,
  Flex,
  Image,
  useDisclosure,
  Fade,
  Link,
  Divider,
} from "@chakra-ui/react";
import "@fontsource/nunito";
import { FiSend } from "react-icons/fi";

export default function LandingPage() {
  const { isOpen, onOpen } = useDisclosure();
  const showLP = useDisclosure();
  const { x } = useSpring({
    from: { x: 0 },
    to: [{ x: 10 }, { x: 0 }],
    loop: true,
    reset: true,
    config: { duration: 1000 },
  });
  const { x2 } = useSpring({
    from: { x2: 0 },
    to: [{ x2: 10 }, { x2: 0 }],
    loop: true,
    reset: true,
    // config: config.gentle,
    config: { duration: 1300 },
  });
  const { x3 } = useSpring({
    from: { x3: 0 },
    to: [{ x3: 10 }, { x3: 0 }],
    loop: true,
    reset: true,
    // config: config.molasses,
    config: { duration: 1150 },
  });

  const fadeOut = useSpring({
    to: { opacity: isOpen ? 0 : 1 },
    onRest: () => {
      showLP.onOpen();
    },
  });

  const fadeIn = useSpring({ opacity: isOpen ? 1 : 0 });
  const Boop = ({ mag = 5, timing = 350, children }: any) => {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
      display: "inline-flex",
      transform: isBooped ? `translate(0px, -${mag}px)` : `translate(0px, 0px)`,
      // config: {
      //   tension: 150,
      //   friction: 7,
      // },
    });
    React.useEffect(() => {
      // Unchanged
      setTimeout(function () {
        setIsBooped(!isBooped);
      }, timing);
    }, [isBooped, timing]);
    return <animated.div style={style}>{children}</animated.div>;
  };
  window.onload = () => {
    onOpen();
    // setTimeout(() => {
    //   onOpen();
    // }, 1000);
  };
  // useEffect(() => {
  //   onOpen();
  // }, []);
  return (
    <div>
      <animated.div style={fadeOut}>
        <Boop>
          <Flex w="100vw" h="95vh" alignItems="center" justifyContent="center">
            <Image
              maxW="200px"
              src="/static/images/mini-mail-small.jpg"
            ></Image>
          </Flex>
        </Boop>
      </animated.div>

      <Fade in={showLP.isOpen}>
        <Flex
          position="absolute"
          top="0"
          direction="column"
          align="center"
          justifyContent="center"
          minH="95vh"
          w="100vw"
          fontFamily='"Nunito", sans-serif;'
        >
          <Flex
            flexGrow="2"
            w="100vw"
            maxW="1200px"
            direction="row"
            align="center"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            <VStack align="left" ml="5" mr="5">
              <Text
                w="fit-content"
                bgGradient="linear(to-l, #fe7582, #6f5ee0)"
                bgClip="text"
                fontSize="4xl"
                fontWeight="extrabold"
              >
                Mail @lpha
              </Text>
              {/* <Text fontSize="70px" lineHeight="1.2" color="#853773" fontWeight="800" bgGradient='linear(to-l, #ffa34b, #ff919e)'
              bgClip='text'>Batch Email Sender</Text> */}
              {/* <animated.p
                style={{
                  color: color1.to((v) => `${v}`),
                  fontSize: "60px",
                  fontWeight: "800",
                }}
              >
                Batch Email Sender
              </animated.p> */}
              <Text
                fontSize="55px"
                w="fit-content"
                bgGradient="linear(to-l, #ffa34b, #ff919e)"
                fontWeight="800"
                bgClip="text"
              >
                Batch Email Sender
              </Text>
              <Text fontSize="xl" color="#26264f" fontWeight="400">
                <b style={{}}>Send mass emails</b> from your Google Mail
              </Text>
              <Text fontSize="xl" color="#26264f" fontWeight="400">
                <b>Track</b> when your email is viewed
              </Text>
              <Text fontSize="xl" color="#26264f" fontWeight="400">
                <b>Track</b> user link clicked
              </Text>
              <Text fontSize="xl" color="#26264f" fontWeight="400">
                <b>Free</b> to use
              </Text>
              <HStack>
                <Button
                  variant="gradientStyleOrange"
                  onClick={() => {
                    window.location.href = "/send-mail";
                  }}
                  boxShadow="sm"
                  leftIcon={<FiSend />}
                >
                  Send mails now
                </Button>
                {/* <Button boxShadow='sm' leftIcon={<FiLogIn/>}>Login</Button> */}
              </HStack>
            </VStack>
            <Box w="40vw" maxW="300px">
              {/* <Image maxW="40vw" src="../static/images/lp2.jpg" alt="send mail"/> */}
              <animated.div
                style={{
                  transform: x2.to((v) => `translate(${v}px, -${v}px)`),
                  position: "absolute",
                  zIndex: "5",
                  width: "40vw",
                  maxWidth: "300px",
                }}
              >
                <Image
                  position="absolute"
                  top="0"
                  w="100%"
                  zIndex="2"
                  src="../static/images/bg_element_01.png"
                  alt="send mail"
                />
              </animated.div>
              <animated.div
                style={{
                  transform: x.to((v) => `translate(${v}px, -${v / 3}px)`),
                  position: "absolute",
                  zIndex: "5",
                  width: "40vw",
                  maxWidth: "300px",
                }}
              >
                <Image
                  position="absolute"
                  top="0"
                  w="100%"
                  zIndex="3"
                  src="../static/images/bg_element_02.png"
                  alt="send mail"
                />
              </animated.div>
              <animated.div
                style={{
                  transform: x2.to((v) => `translate(${v}px, ${v / 3}px)`),
                  position: "absolute",
                  zIndex: "5",
                  width: "40vw",
                  maxWidth: "300px",
                }}
              >
                <Image
                  position="absolute"
                  top="0"
                  w="100%"
                  zIndex="4"
                  src="../static/images/bg_element_03.png"
                  alt="send mail"
                />
              </animated.div>
              <animated.div
                style={{
                  transform: x3.to((v) => `translate(0, ${v}px)`),
                  position: "absolute",
                  zIndex: "5",
                  width: "40vw",
                  maxWidth: "300px",
                }}
              >
                <Image
                  position="absolute"
                  top="0"
                  w="100%"
                  zIndex="5"
                  src="../static/images/bg_element_04.png"
                  alt="send mail"
                />
              </animated.div>
              <Image
                top="0"
                w="100%"
                zIndex="1"
                src="../static/images/bg_base-small.jpg"
                alt="send mail"
              />
            </Box>
          </Flex>
          <Flex
            mt="10"
            width="90vw"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text
              bgGradient="linear(to-l, #fe7582, #6f5ee0)"
              bgClip="text"
              fontSize="sm"
              ml="2"
              mr="2"
              fontWeight="extrabold"
              maxW="200px"
              alignSelf="center"
              w="fit-content"
            >
              Made with ♥️ from Japan | Beta 0.0.1
            </Text>
            <HStack fontSize="sm">
              <Link href="/privacy">Privacy Policy</Link>
              <Divider ml="30px" mr="30px" orientation="vertical" h="20px" />
              <Link href="/terms">Terms of use</Link>
            </HStack>
          </Flex>
        </Flex>
      </Fade>

      <animated.div style={fadeIn}></animated.div>
    </div>
  );
}
