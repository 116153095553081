import React, { ReactNode, useEffect, useState } from "react";
import { useTrail, a, useSpring, config, animated } from "@react-spring/web";
import {
  useDisclosure,
  Flex,
  Textarea,
  Text,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import "@fontsource/nunito";
import Select from "react-select";
import { FiMenu, FiSend, FiLogOut } from "react-icons/fi";
import { FaGoogle } from "react-icons/fa";
import {
  MdNavigateNext,
  MdOutlineFileUpload,
  MdNavigateBefore,
} from "react-icons/md";
import { MdCampaign } from "react-icons/md";
import {
  clientLoad,
  login,
  logout,
  listLabels,
  sendEmail,
  getCurrentUser,
  requestSendEmailScope,
  isSendEmailScope,
} from "../Module/gapi";
import { WithContext as ReactTags } from "react-tag-input";
import { MailTrackUsecase, TrackEmail } from "../Usecase/MailTrackUsecase";
import moment from "moment";
import CommonAlertDialog from "../Components/Commons/CommonAlertDialog";
import qs from "qs";

export default function OpenMailTrackPage() {
  const alertDialogDisclosure = useDisclosure();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [mailTrackDatas, setMailTrackDatas] = useState<TrackEmail[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  // --- end of upload file helper
  useEffect(() => {
    const loadData = async () => {
      const session =
        (qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        }).query as string) ?? "";
      const usecase = new MailTrackUsecase();
      const loadResult = await usecase.GetTrackedMails(session);
      setMailTrackDatas(loadResult);
      console.log(loadResult);
      setIsLoaded(true);
    };
    loadData();
  }, []);

  // handle send target tags
  // const suggestions: any[] = [{id: 'nice', text: 'nice'}, {id: 'nice2', text: 'nice2'}]

  const showAlert = (title: string, content: string) => {
    setAlertTitle(title);
    setAlertContent(content);
    alertDialogDisclosure.onOpen();
  };

  const Boop = ({ mag = 0, timing = 150, children }: any) => {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
      margin: "20px auto",
      display: "inline-flex",
      transform: isBooped ? `translate(0px, -${mag}px)` : `translate(0px, 0px)`,
      config: {
        tension: 300,
        friction: 7,
      },
    });
    React.useEffect(() => {
      // Unchanged
      setTimeout(function () {
        setIsBooped(false);
      }, timing);
    }, [isBooped, timing]);
    const trigger = () => {
      // Unchanged
      setIsBooped(true);
    };
    return (
      <animated.div onMouseEnter={trigger} style={style}>
        {children}
      </animated.div>
    );
  };

  return (
    <Flex m="5" direction="column" minH="95vh">
      <Text
        w="fit-content"
        bgGradient="linear(to-l, #fe7582, #6f5ee0)"
        bgClip="text"
        fontSize="4xl"
        fontWeight="extrabold"
      >
        Mail @lpha
      </Text>
      <Text
        w="fit-content"
        fontSize="50px"
        lineHeight="1.2"
        color="#853773"
        fontWeight="800"
        bgGradient="linear(to-l, #ffa34b, #ff919e)"
        bgClip="text"
      >
        Check Mail Status
      </Text>

      <VStack flexGrow="2" w="fit-content" m="auto">
        {!isLoaded && <Spinner alignSelf="center" color="red.500" size="lg" />}
        {isLoaded && (
          <Table variant="simple" colorScheme="facebook">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Mail open times</Th>
                <Th>Last mail open</Th>
                <Th>Last link click</Th>
              </Tr>
            </Thead>
            <Tbody>
              {mailTrackDatas.map((row: TrackEmail, index) => {
                return (
                  <Tr key={row.email}>
                    <Td>{row.email}</Td>
                    <Td>{row.count}</Td>
                    <Td>
                      {row.firstOpen
                        ? moment(row.firstOpen).format("yyyy/MM/DD HH:mm")
                        : ""}
                    </Td>
                    <Td>
                      {row.lastOpenLink
                        ? moment(row.lastOpenLink).format("yyyy/MM/DD HH:mm")
                        : ""}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </VStack>
      <Text
        bgGradient="linear(to-l, #fe7582, #6f5ee0)"
        bgClip="text"
        fontSize="sm"
        mb="2"
        ml="2"
        mr="2"
        fontWeight="extrabold"
        maxW="200px"
        alignSelf="center"
        w="fit-content"
      >
        Made with ♥️ from Japan
      </Text>
      <CommonAlertDialog
        isOpen={alertDialogDisclosure.isOpen}
        onClose={alertDialogDisclosure.onClose}
        title={alertTitle}
        content={alertContent}
      />
    </Flex>
  );
}
