import axios from "axios";

const API_KEY = "AIzaSyAtdkQoB5zuv51hIhQptIDbUT-MDzoWLU8";
const CLIENT_ID =
  "94003822174-p2af90gnmceuvut22frddua7a6bgrv97.apps.googleusercontent.com";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
];
export const SCOPES = "https://www.googleapis.com/auth/gmail.send";

const gapi = window.gapi;

export function clientLoad(updateSigninStatus) {
  gapi.load("client:auth2", () => {
    initClient(updateSigninStatus);
  });
}

function initClient(updateSigninStatus) {
  gapi.client
    .init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(
      function () {
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      },
      function (error) {
        console.log("init gapi error:", error.details);
      }
    );
}

export function getCurrentUser() {
  var auth2 = gapi.auth2.getAuthInstance();
  var profile = auth2.currentUser.get().getBasicProfile();
  return profile;
}

export function isSendEmailScope() {
  var auth2 = gapi.auth2.getAuthInstance();
  var isScope = auth2.currentUser.get().hasGrantedScopes(SCOPES);
  return isScope;
}

export function login() {
  var options = new gapi.auth2.SigninOptionsBuilder();
  options.setScope(SCOPES);

  // var googleUser = gapi.auth2.currentUser.get();
  // googleUser.grant(options);
  // gapi.auth2.getAuthInstance().signIn();

  window.google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: SCOPES,
    callback: (tokenResponse) => {
      console.log("access token:", tokenResponse.access_token);
      // access_token = tokenResponse.access_token;
    },
  });
}

export async function requestSendEmailScope() {
  var options = new gapi.auth2.SigninOptionsBuilder({
    scope: SCOPES,
  });
  var auth2 = gapi.auth2.getAuthInstance();
  var googleUser = auth2.currentUser.get();
  await googleUser.grant(options);

  // .then(
  //   function (success) {
  //     console.log(JSON.stringify({ message: "success", value: success }));
  //   },
  //   function (fail) {
  //     console.log(JSON.stringify({ message: "fail", value: fail }));
  //   }
  // );
}

export function logout() {
  gapi.auth2.getAuthInstance().signOut();
}

export function listLabels() {
  gapi.client.gmail.users.labels
    .list({
      userId: "me",
    })
    .then(function (response) {
      var labels = response.result.labels;
      console.log("gmail result:", labels);
    });
}
const API_BASE_URL = "https://gmail.googleapis.com";

export async function getTokens(params) {
  const URL = `${API_BASE_URL}/tokens`;

  const res = await axios.get(URL, { params }).catch((e) => {
    console.log(e);
    throw e;
  });
  return res.data;
}

export async function getEmail(token) {
  //www.googleapis.com/oauth2/v2/userinfo
  if (!token) {
    throw Error("No token");
  }

  axios.defaults.headers.common = {
    Authorization: `Bearer ${token.access_token}`,
  };
  const URL = `https://www.googleapis.com/oauth2/v2/userinfo`;

  const res = await axios.get(URL).catch((e) => {
    console.log(e);
    throw e;
  });
  console.log(res);
  return res.data.email;
}

export async function sendEmail(token, email, from, to, subject, message) {
  //test
  //return { status: 400 };
  if (!token) {
    throw Error("No token");
  }
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token.access_token}`,
  };
  // const realEmail = getCurrentUser().getEmail();
  const realEmail = email;
  console.log("from:", from);
  var raw = makeBody(to, from, subject, message, realEmail);
  const URL = `https://gmail.googleapis.com/gmail/v1/users/me/messages/send`;

  const res = await axios.post(URL, { raw }).catch((e) => {
    console.log(e);
    throw e;
  });
  return res;
  // var response = await gapi.client.gmail.users.messages.send({
  //   userId: "me",
  //   resource: {
  //     raw,
  //   },
  // });
  // console.log("Gmail result:", response);
  // return response;
}

function makeBody(to, from, subject, message, realEmail) {
  const subjectBuffer = Buffer.from(subject);
  const fromBuffer = Buffer.from(`${from}`);
  const fromUtf = `=?utf-8?B?${fromBuffer.toString("base64")}?= <${realEmail}>`;
  // const fromUtf = from;
  var str = [
    'Content-Type: text/html; charset="UTF-8"\r\n',
    "MIME-Version: 1.0\r\n",
    "Content-Transfer-Encoding: 7bit\r\n",
    "to: ",
    to,
    "\r\n",
    "from: ",
    fromUtf,
    `\r\n`,
    "subject: =?utf-8?B?",
    subjectBuffer.toString("base64"),
    "?=\r\n\r\n",
    message,
  ].join("");

  console.log("email:", str);

  const encodedMail = Buffer.from(str)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

  return encodedMail;
}
