import {
  useDisclosure,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Text,
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiSend } from "react-icons/fi";
import React, { useRef } from "react";

export interface WhatsCSVModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ReplaceTextModal(props: WhatsCSVModalProps) {
  const { onClose, isOpen } = props;
  const cancelRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Custom mail content for each receiver</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mt="0">
            By import data from a CSV file, you can also custom mail content for
            each receiver base on your CSV data
          </Text>

          <Text mt="5">
            For example, if your CSV have a column name <b>Name</b>, you can use
            the <b>:Name:</b> code to automatically replace the name value to
            each receiver
          </Text>
          <Image src="/static/images/csv3.png" />
        </ModalBody>

        <ModalFooter>
          <Button variant="gradientStyle" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
