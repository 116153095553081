import {
  useDisclosure,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Text,
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiSend } from "react-icons/fi";
import React, { useRef } from "react";

export interface WhatsCSVModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function WhatsCSVModal(props: WhatsCSVModalProps) {
  const { onClose, isOpen } = props;
  const cancelRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>What's CSV ?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mt="0" fontWeight="bold">
            Definition
          </Text>
          <Text mt="0">
            Comma Separated Values (CSV) file is a plain text file that contains
            a list of data.
          </Text>

          <Text mt="5" fontWeight="bold">
            How to get CSV file ?
          </Text>

          <Text mt="0">
            You can export CSV file from your Excel or Speadsheet files under{" "}
            <b>"Save as"</b> or <b>"Export as"</b>
          </Text>
          <Image src="https://support.ecwid.com/hc/article_attachments/360006016239/Import-export_CSV_files_to_Excel___8_.png" />

          <Text mt="2">
            While <b>header (column name)</b> is optional in standard CSV, our
            application require your CSV to have the header line
          </Text>
          <Image src="/static/images/csv3.png" />
        </ModalBody>

        <ModalFooter>
          <Button variant="gradientStyle" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
