import React, { ReactNode, useEffect, useState } from "react";
import {
  useDisclosure,
  Flex,
  Textarea,
  Text,
  Input,
  Button,
  Container,
  Box,
  HStack,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { FiMenu, FiSend } from "react-icons/fi";
import { FaGoogle } from "react-icons/fa";
import { MdCampaign } from "react-icons/md";
import {
  clientLoad,
  login,
  logout,
  listLabels,
  sendEmail,
  getCurrentUser,
} from "../Module/gapi";
import { WithContext as ReactTags } from "react-tag-input";
import { AudienceUsecase } from "../Usecase/AudienceUsecase";
import moment from "moment";
import "../static/input-tag.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Audience, AudienceData, AudienceTag } from "../Entity";
import { CKEditor } from "ckeditor4-react";

const LinkItems = [{ name: "キャンペーン", icon: MdCampaign }];

const ExpectAction = {
  Init: "init",
  SendMail: "sendmail",
  Login: "login",
};

export default function SendMailPage() {
  const [mailFrom, setMailFrom] = useState("");
  const [mailto, setMailTo] = useState("");
  const [mailTitle, setMailTitle] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expectAction, setExpectAction] = useState(ExpectAction.Init);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<AudienceTag[]>([]);
  const [currentGmailUser, setCurrentGmailUser] = useState<any>(null);

  const gapi = window.gapi;
  const audienceUsecase = new AudienceUsecase();

  function updateSigninStatus(isSignedIn: boolean) {
    if (isSignedIn) {
      console.log("gapi: signed in expect:", expectAction);
      setCurrentGmailUser(getCurrentUser());
      if (expectAction === ExpectAction.SendMail) {
        if (window.confirm("Do you really want to send mail ?")) {
          sendEmail(mailFrom, mailto, mailTitle, mailContent);
        }
      }
      // listLabels()
      // sendEmail()
    } else {
      console.log("gapi: signed out");
    }
  }

  useEffect(() => {
    const initData = async () => {
      const tags = await audienceUsecase.getTags();
      console.log(tags);
      setSuggestions(tags);
    };
    initData();
    clientLoad(updateSigninStatus);
    setMailTitle(localStorage.getItem("mailTitle") ?? "");
    setMailContent(localStorage.getItem("mailContent") ?? "");
    setMailFrom(localStorage.getItem("mailFrom") ?? "");
  }, []);

  // handle send target tags
  // const suggestions: any[] = [{id: 'nice', text: 'nice'}, {id: 'nice2', text: 'nice2'}]

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [tags, setTags] = React.useState<AudienceTag[]>([]);
  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: AudienceTag) => {
    if (suggestions.map((s) => s.id).includes(tag.id)) {
      setTags([...tags, tag]);
    }
  };

  const handleDrag = (tag: AudienceTag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const performSendMail = async () => {
    if (!currentGmailUser) {
      alert("Please login Gmail");
      return;
    }

    if (tags.length === 0) {
      alert("please select tags");
      return;
    }

    if (mailTitle === "" || mailContent === "") {
      alert("please input mail title / mail content");
      return;
    }

    if (
      !window.confirm(
        "Mail will be sent to all audiences of the selected tag(s). This operation cannot be undone, are you ready ?"
      )
    ) {
      return;
    }

    const getTargetAudiences = await audienceUsecase.getAudiencesByTag(tags[0]);
    const audienceDatas = await audienceUsecase.getAudienceDatas();
    let rpMailContent = mailContent;

    for (const audience of getTargetAudiences) {
      const rawData = JSON.parse(audience.rawData);
      // replace template
      // eslint-disable-next-line no-loop-func
      audienceDatas.forEach((data) => {
        rpMailContent = rpMailContent.replaceAll(
          `:${data.id}`,
          rawData[audience.dataMap[data.label]] ?? ""
        );
      });

      sendEmail(
        `${mailFrom} <${currentGmailUser?.getEmail()}>`,
        audience.email,
        mailTitle,
        rpMailContent
      );
    }
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "25px" }}>
      <Text fontSize="2xl" fontWeight="bold" mb="5" pl="2">
        Send Mail
      </Text>
      <Box
        backgroundColor="#ffffff90"
        padding="20px"
        borderRadius="20px"
        boxShadow="sm"
      >
        <InputGroup>
          <InputLeftAddon minW="110px" children="Mail from" />
          <Input
            backgroundColor="white"
            variant="outline"
            focusBorderColor="red.400"
            placeholder="Mail from"
            value={mailFrom}
            onChange={(e) => {
              localStorage.setItem("mailFrom", e.target.value);
              setMailFrom(e.target.value);
            }}
          />
        </InputGroup>
        <InputGroup mt="5">
          <InputLeftAddon minW="110px" children="Mail title " />
          <Input
            backgroundColor="white"
            variant="outline"
            focusBorderColor="red.400"
            placeholder="Mail title"
            value={mailTitle}
            onChange={(e) => {
              localStorage.setItem("mailTitle", e.target.value);
              setMailTitle(e.target.value);
            }}
          />
        </InputGroup>
        <HStack mt="5">
          <Text>Taget by tags</Text>
          <ReactTags
            tags={tags}
            suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete={true}
            minQueryLength={1}
            placeholder="Taget tags"
          />
        </HStack>
        {/* <Text>Mail From</Text>
        <Input value={mailFrom} onChange={(e) => {
          localStorage.setItem("mailFrom", e.target.value);
          setMailFrom(e.target.value)
        }} placeholder='Mail From'></Input>
        <Text>Mail Title</Text>
        <Input value={mailTitle} onChange={(e) => {
          localStorage.setItem("mailTitle", e.target.value);
          setMailTitle(e.target.value)
        }} placeholder='Mail Title'></Input> */}
        <div
          style={{
            backgroundColor: "#ffffff",
            minHeight: "6em",
            cursor: "text",
            marginTop: "10px",
          }}
        >
          <CKEditor
            initData={localStorage.getItem("mailContent")}
            onChange={(e) => {
              localStorage.setItem("mailContent", e.editor.getData());
              setMailContent(e.editor.getData());
            }}
          />
        </div>
        <VStack mt="5" align="left">
          <HStack>
            <Text>Sending Account</Text>
            <Tag colorScheme="red">
              {currentGmailUser?.getEmail() ?? "Not linking email yet"}
            </Tag>
          </HStack>
          <Button
            variant="outline"
            maxW="250px"
            colorScheme="red"
            leftIcon={<FaGoogle />}
            onClick={() => {
              setExpectAction(ExpectAction.Login);
              login();
            }}
          >
            Login Email Account
          </Button>
          <Button
            variant="outline"
            maxW="250px"
            colorScheme="red"
            leftIcon={<FaGoogle />}
            onClick={() => {
              console.log(mailContent);
            }}
          >
            get html code
          </Button>
        </VStack>

        <br />
        <Button
          colorScheme="red"
          leftIcon={<FiSend />}
          onClick={() => {
            // setExpectAction(ExpectAction.SendMail)
            // login()
            // clientLoad(updateSigninStatus)
            performSendMail();
          }}
        >
          Send Bulk Mail
        </Button>
      </Box>
    </Flex>
  );
}
