import { Audience, AudienceData, AudienceTag } from "../Entity";
import { db } from "../firebase";
import {
  connectFirestoreEmulator,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  Timestamp,
  addDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { MailListItem } from "../Entity/MailListItem";

export type AudienceFilter = {
  searchKeyword?: string;
  tag?: string;
  email?: string;
  name?: string;
  page: number;
  itemPerPage: number;
};

export class MailListUsecase {
  team: string = "openAll";

  async addCallback(callBackId: string, email: string) {
    const sessionDoc = doc(db, "callbacks", callBackId);

    // const sessionDoc = doc(db, "teams", this.team, "sessions", session);
    // setDoc(sessionDoc, { trackCode: tradeCode }, { merge: true });

    await setDoc(sessionDoc, {
      email: email,
      createdAt: Timestamp.now(),
    });
  }

  async importMailListText(
    text: string,
    session: string
  ): Promise<MailListItem[]> {
    const mailListItems: MailListItem[] = [];
    console.log(text.split(`\n`));
    for (const email of text.split(`\n`)) {
      const collectionRef = collection(
        db,
        "teams",
        this.team,
        "sessions",
        session,
        "datas"
      );
      const callBackId = uuidv4();
      mailListItems.push({
        email,
        callBackId,
      });

      // this.addCallback(callBackId, email);
      // const sessionDoc = doc(db, "teams", this.team, "sessions", session);
      // setDoc(sessionDoc, { trackCode: tradeCode }, { merge: true });

      await addDoc(collectionRef, {
        email: email,
        createdAt: Timestamp.now(),
        callBackId,
      });
    }
    return mailListItems;
  }

  async updateMailInfo(
    session: string,
    title: string,
    content: string,
    from: string
  ) {
    const dataRef = doc(db, "teams", this.team, "sessions", session);

    await setDoc(
      dataRef,
      {
        title,
        content,
        from,
        updatedAt: Timestamp.now(),
      },
      { merge: true }
    );
  }

  async importMaillist(
    csvDatas: any[],
    emailColumnName: string,
    session: string
  ): Promise<MailListItem[]> {
    const mailListItems: MailListItem[] = [];
    for (const data of csvDatas) {
      console.log(data);
      const collectionRef = collection(
        db,
        "teams",
        this.team,
        "sessions",
        session,
        "datas"
      );
      const searchKeywords = [""];
      const callBackId = uuidv4();
      mailListItems.push({
        email: data[emailColumnName] ?? "",
        data,
        callBackId,
      });

      for (const attr in data) {
        searchKeywords.push(data[attr]);
      }

      // this.addCallback(callBackId, data[emailColumnName] ?? "");
      await addDoc(collectionRef, {
        email: data[emailColumnName] ?? "",
        rawData: JSON.stringify(data),
        createdAt: Timestamp.now(),
        searchKeywords,
        callBackId,
      });
    }
    return mailListItems;
  }
}
