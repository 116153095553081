export class AudienceTag {
    id: string
    text: string
  
    constructor (
      id: string,
      text: string
    ) {
      this.id = id
      this.text = text
    }
  }