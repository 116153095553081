import React, { useEffect, useState } from "react";
import {
  Button,
  VStack,
  Container,
  Spinner,
  Text,
  Input,
  HStack,
  Box
} from "@chakra-ui/react";
import { auth } from "./firebase";
import { BiLogInCircle } from 'react-icons/bi'
import { signInWithEmailAndPassword } from "firebase/auth"

export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const setNameInfo = async () => {

    };
    setNameInfo();
  }, []);
  const signIn = async (email: string, password: string) => {
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setIsLoading(false)
          const user = userCredential.user;
          console.log(user)
          window.location.href = '/'
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error)
          const errorMessage = error.message;
          setErrorText(`Login failed：`+ errorMessage)
          console.log(errorMessage)
        });
  };
  return (
    <div>
      <VStack>
        <Container mt='20vh'>
          <Box boxShadow='2xl' p='10' borderRadius='20px'>
            <HStack>
              <Text fontSize='xl'>Login into</Text>
              <Text fontSize='2xl' fontWeight="bold">Mail Alpha</Text>
            </HStack> 
            <Input focusBorderColor='red.400' borderRadius='20px' mt='2'  type='email'  value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email'></Input>
            <Input focusBorderColor='red.400' borderRadius='20px' mt='2' type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password'></Input>
            <Button pl='10' pr='10' borderRadius='20px' mt='10' colorScheme='red' onClick={() => signIn(email, password)} leftIcon={<BiLogInCircle/>}>Login</Button>
            <Text color='red.500'>{errorText}</Text>
          </Box>
        </Container>
        {isLoading && <Spinner />}{" "}
      </VStack>
    </div>
  );
}

