import {
  useDisclosure,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Text,
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiSend } from "react-icons/fi";
import React, { useRef } from "react";

export interface WhatsCSVModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function BybassVerifyModal(props: WhatsCSVModalProps) {
  const { onClose, isOpen } = props;
  const cancelRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Google hasn't verified this app ?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mt="0" fontWeight="bold">
            In case of getting <b>Google hasn't verified this app</b> error
          </Text>
          <Text mt="0">
            Please click on <b>Advanced</b> and then <b>Go to MailAlpha</b> as
            the image follow:
          </Text>
          <Image src="/static/images/google-error.png" />
        </ModalBody>

        <ModalFooter>
          <Button variant="gradientStyle" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
