import { Audience, AudienceData, AudienceTag } from '../Entity'
import { db } from "../firebase"
import { 
    connectFirestoreEmulator, getFirestore, doc, 
    getDoc, getDocs, collection, setDoc, Timestamp, 
    deleteDoc, runTransaction, query, where, startAt, limit,
    orderBy
} from "firebase/firestore";

export type AudienceFilter = {
    searchKeyword?: string
    tag?: string
    email?: string
    name?: string
    page: number
    itemPerPage: number
}

export class AudienceUsecase {
    team: string = "k9azuUEhDDIpZXGyKkmc"
    async getAudienceDatas (teamsId?: string): Promise<AudienceData[]> {
        let audienceDatas = []

        const collectionsRef = collection(db, 'teams', this.team, 'datadefines');
        const q = query(collectionsRef);
        const querySnapshot = await getDocs(q);

        let result: AudienceData[] = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(new AudienceData(doc.id, doc.data().display))
        });
        // console.log('audience query result:', querySnapshot.docs)
        // var result = querySnapshot.docs.map((snap: any) => snap as Audience)
        return result
    }

    async getAudiencesByTag(tag: AudienceTag): Promise<Audience[]> {
        const audiencesRef = collection(db, 'teams', this.team, 'audiences');
        const q = query(audiencesRef, where('tags', 'array-contains', tag.id));
        const querySnapshot = await getDocs(q);

        let result: Audience[] = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            const audience = doc.data() as Audience
            audience.rawObject = JSON.parse(audience.rawData)
            result.push(audience)
        });
        // console.log('audience query result:', querySnapshot.docs)
        // var result = querySnapshot.docs.map((snap: any) => snap as Audience)
        return result
    }

    async getTags(): Promise<AudienceTag[]> {
        const collectionsRef = collection(db, 'teams', this.team, 'tags');
        const q = query(collectionsRef);
        const querySnapshot = await getDocs(q);

        let result: AudienceTag[] = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(new AudienceTag(doc.id, doc.id))
        });
        // console.log('audience query result:', querySnapshot.docs)
        // var result = querySnapshot.docs.map((snap: any) => snap as Audience)
        return result
    } 

    async importAudience(csvDatas: any[], fields: string[], importDataDefine: (string|null)[], tags: AudienceTag[], audienceDatas: AudienceData[]) {
        console.log(tags)
        // setTags
        tags.forEach(async data => {
            console.log(data)
            const emailColumnName = fields[importDataDefine.indexOf('email')]
            const fullNameColumn = fields[importDataDefine.indexOf('name')]
            const docRef = doc(db, 'teams', this.team, 'tags', data.id);
            await setDoc(docRef, {
                
            });
            // transaction.set(docRef, {code: code.code, flowAddress: '', itemID: promotionId});
          })

        csvDatas.forEach(async data => {
            console.log(data)
            const emailColumnName = fields[importDataDefine.indexOf('email')]
            const fullNameColumn = fields[importDataDefine.indexOf('name')]
            const docRef = doc(db, 'teams', this.team, 'audiences', data[emailColumnName]);
            const searchKeywords = ['']

            const dataMap: any = {}
            audienceDatas.forEach(data => {
                dataMap[data.id] = fields[importDataDefine.indexOf(data.id)]
            })

            searchKeywords.push(...(tags.map((a:AudienceTag) => a.id)))
            for (const attr in data) {
                searchKeywords.push(data[attr])
            }
            await setDoc(docRef, {
                email: data[emailColumnName] ?? '',
                fullName: data[fullNameColumn] ?? '',
                rawData: JSON.stringify(data),
                tags: tags.map((a:AudienceTag) => a.id),
                createdAt: Timestamp.now(),
                searchKeywords,
                dataMap
            }, { merge: true });

            
            // transaction.set(docRef, {code: code.code, flowAddress: '', itemID: promotionId});
          })
    }

   async deleteAudience(audience: Audience) {
    const docRef = doc(db, 'teams', this.team, 'audiences', audience.email);
    await deleteDoc(docRef);
   }

    async getAudiences(filter: AudienceFilter): Promise<Audience[]> {
        const audiencesRef = collection(db, 'teams', this.team, 'audiences');
        console.log('query keyword:', filter.searchKeyword)
        const q = query(audiencesRef, where('searchKeywords', 'array-contains', filter.searchKeyword), orderBy('email'), startAt(filter.itemPerPage * (filter.page - 1)), limit(filter.itemPerPage));
        const querySnapshot = await getDocs(q);

        let result: Audience[] = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const audience = doc.data() as Audience
            audience.rawObject = JSON.parse(audience.rawData)
            console.log(doc.id, " => ", audience);
            result.push(audience)
        });
        // console.log('audience query result:', querySnapshot.docs)
        // var result = querySnapshot.docs.map((snap: any) => snap as Audience)
        return result
    }
}