import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, Link } from "react-router-dom";
import Promotion from "./Promotion.js";
import AdminLogin from "./AdminLogin";
import AdminHome from "./AdminHome";
import LandingPage from "./Page/LandingPage";
import PrivacyPolicy from "./Page/PrivacyPolicy";
import OpenSendMailPage from "./Page/OpenSendMailPage";
import OpenMailTrackPage from "./Page/OpenMailTrackPage";
import TermService from "./Page/TermService";
import "./static/App.css";
import { extendTheme } from "@chakra-ui/react";
import theme from "./Theme/GradientTheme";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <GoogleOAuthProvider clientId="94003822174-p2af90gnmceuvut22frddua7a6bgrv97.apps.googleusercontent.com">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermService />} />
            <Route path="/send-mail" element={<OpenSendMailPage />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/mail-track" element={<OpenMailTrackPage />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/:itemID" element={<Promotion />} />
          </Routes>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
