import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Flex,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Button,
  useColorModeValue,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Text,
  Select,
  Tag,
  InputGroup,
  InputLeftAddon,
  HStack,
  VStack,
  InputLeftElement,
  IconButton
} from "@chakra-ui/react";
import { AudienceUsecase } from "../Usecase/AudienceUsecase";
import { Audience, AudienceData, AudienceTag } from '../Entity'
import { WithContext as ReactTags } from 'react-tag-input';
import moment from 'moment';
import '../static/input-tag.css'
import InfiniteScroll from "react-infinite-scroll-component"
import {
  HiUserGroup,
  HiMailOpen,
  HiUpload,
  HiSearch,
  HiSearchCircle,
  HiPencil,
  HiExternalLink,
  HiChevronRight
} from 'react-icons/hi'
import {
  RiCheckboxMultipleBlankFill,
  RiDeleteBin4Fill,
  RiMailOpenFill
} from 'react-icons/ri'
const Papa = require('papaparse');



function CampaignTables() {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [importedColumns, setImportedColumns] = useState<string[]>([])
  const [audienceDatas, setAudienceDatas] = useState<AudienceData[]>([])
  const [selectedImportAudienceDatas, setSelectedImportAudienceDatas] = useState<(string|null)[]>([])
  const [csvData, setCsvData] = useState<any>(null)
  const [search, setSearch] = useState('')
  const [checkEmails, setCheckEmails] = useState<string[]>([])
  const [defaultCheck, setDefaultCheck] = useState(false);
  
  
  // --- for audience tables
  const [audiences, setAudiences] = useState<Audience[]>([])
  const [page, setPage] = useState<number>(0)
  const [itemPerPage, setItemPerPage] = useState<number>(10)
  const [hasMore, setHasMore] = useState<boolean>(true)

  // --- init data for import tag
  const suggestions: any[] = []
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [tags, setTags] = React.useState<AudienceTag[]>([]);
  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: AudienceTag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: AudienceTag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };



  const audienceUsecase: AudienceUsecase = new AudienceUsecase()
  useEffect(() => {
    const initData = async () => {
      beginSearch()
      setAudienceDatas(await audienceUsecase.getAudienceDatas())
    }
    initData()
  }, []);

  // useEffect(() => {
  //   const initData = async () => {
  //     loadMoreAudience()
  //   }
  //   initData()
  // }, [search]);


  // --- for upload csv support
  const input = document.createElement('input')
  let uploadingItemId = undefined
  const loadFile = (e: any) => {
    input.type = 'file'
    input.accept = ".csv"
    input.addEventListener("change", handleChangeFile)
    input.click()
  }

  const handleChangeFile = (evt: any) => {
    setIsLoading(true);
    var file = evt.target.files[0]
    let fileExtension = getFileExtension(file.name);

    if (!fileExtension || fileExtension[0].toString().localeCompare("csv")) {
      alert("CSVファイルを選択ください");
      return;
    };
    Papa.parse(file, {
      complete: async function(results: any, file: any) {
        try {
          console.log(results)
          console.log(results.data[0][results.meta.fields[0]])
          setSelectedImportAudienceDatas(results.meta.fields.map((a:string) => null))
          setImportedColumns(results.meta.fields)
          setCsvData(results)
          onOpen()
          // await batchImportCodes(uploadingItemId, results.data)
        } catch (error) {
          setIsLoading(false);
          alert(`エラーが発生しました：${error}`)
          return
        }
        uploadingItemId = undefined
        setIsLoading(false);
      },
      header: true
    });
  }

  const beginImport = () => {
    audienceUsecase.importAudience(csvData.data, csvData.meta.fields, selectedImportAudienceDatas, tags, audienceDatas)
    onClose()
    beginSearch()
  }

  const getFileExtension = (filename: string): RegExpExecArray | null | undefined => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }

  const renderTags = (tags: string[]) => {
    return tags.map((item, index) => 
      <Tag colorScheme='red' borderRadius='full' mr='2'>{ item }</Tag>
    )
  }

  // pagination
  const loadMoreAudience = async () => {
    setPage(page + 1)
    const loadDatas = await audienceUsecase.getAudiences({
      'searchKeyword': search,
      'page': page + 1,
      itemPerPage
    })
    loadDatas.forEach(a => {
      a.isCheck = defaultCheck
    })
    console.log('load more page', loadDatas.length)
    setHasMore(loadDatas.length === itemPerPage)
    setAudiences(audiences.concat(loadDatas))
  }

  const beginSearch = async () => {
    setPage(1)
    const loadDatas = await audienceUsecase.getAudiences({
      'searchKeyword': search,
      'page': 1,
      itemPerPage
    })
    console.log('load more page', loadDatas.length, ' has more:', loadDatas.length === itemPerPage)
    setHasMore(loadDatas.length === itemPerPage)
    loadDatas.forEach(a => {
      a.isCheck = defaultCheck
    })
    setAudiences(loadDatas)
  }

  const isAllowBatch = ():boolean => {
    return defaultCheck || !!audiences.filter(a => a.isCheck)[0]
  }

  const sendMail = () => {
    window.alert('feature on working! try again later')
  }

  const deleteSelected = async () => {
    if (!window.confirm('Are you sure you want to delete selected item? This cannot be undone')) {
      return
    }
    let targetItem: Audience[] = []
    if (defaultCheck) {
      const uncheckedList = audiences.filter(a => !a.isCheck)
      targetItem = await audienceUsecase.getAudiences({
        'searchKeyword': search,
        'page': 1,
        itemPerPage: 10000
      })
      // remove from target if item being unchecked
      targetItem.filter(a => !uncheckedList.filter(b => b.email === a.email)[0])
    } else {
      targetItem = audiences.filter(a => a.isCheck)
    }
    console.log('perform deletes:', targetItem.length)
    for (const audience of targetItem) {
      await audienceUsecase.deleteAudience(audience)
    }
    beginSearch()
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "25px" }}>
      <Text fontSize="2xl" fontWeight="bold" mb="5" pl="2">Audiences</Text>
      <Flex>
      <Box maxW='410px' bg="#ffffff90" p="5" borderRadius="xl" boxShadow='md'>
        <HStack>
          <Box w='60px' h='60px' bg='red.400' boxShadow='md' p='4' borderRadius='xl'><HiUserGroup size='sm' color='white'/></Box>
          <VStack pl='5' pr='5' align='left'>
            <Text fontSize='xl' fontWeight='bold'>x,xxx</Text>
            <Text fontSize='sm' color='gray.500'>Total audience(s)</Text>
          </VStack>
          <Button onClick={loadFile} leftIcon={<HiUpload />} colorScheme='red' variant='ghost'>Import CSV</Button>
        </HStack>
      </Box>
      <Spacer />
      {(isAllowBatch() &&
      <Box maxW='410px' bg="#ffffff90" p="5" borderRadius="xl" boxShadow='md'>
        <HStack>
          <Box w='60px' h='60px' bg='red.400' boxShadow='md' p='4' borderRadius='xl'><RiCheckboxMultipleBlankFill size='sm' color='white'/></Box>
          <Button onClick={sendMail} leftIcon={<RiMailOpenFill />} colorScheme='red' variant='ghost'>Send Mail</Button>
          <Button onClick={deleteSelected} leftIcon={<RiDeleteBin4Fill />} colorScheme='red' variant='ghost'>Delete</Button>
        </HStack>
      </Box>
      )}
      </Flex>
      
        
      <Box bg="#ffffff90" pt="5" pb="5" mt='5' borderRadius='xl' boxShadow='md'>
        <InputGroup ml='5' mr='5'>
          <InputLeftElement
            pointerEvents='none'
            color='gray.300'
            children={<HiSearch color='gray.400' />}
          />
          <Input w='md' value={search} focusBorderColor='red.400' placeholder='Search for audiences' onChange={(e) => {
            console.log('data:', e.target.value, ' - saved:', search)
            setSearch(e.target.value)
          }}/>
          <Button ml='2' colorScheme='red' variant='ghost' leftIcon={<HiSearch/>} onClick={(e) => {
            beginSearch()
          }}>Search</Button>
        </InputGroup>
        <InfiniteScroll
          dataLength={audiences.length}
          next={loadMoreAudience}
          hasMore={hasMore}
          loader={ <Spinner m='5'/>}
        >
          <Table variant='simple' colorScheme='facebook'>
            <Thead>
              <Tr>
                <Th><Checkbox size="lg" colorScheme='red' onChange={(e) => {
                  const _audiences = [...audiences]
                  console.log('check stat:', e.target.checked)
                  setDefaultCheck(e.target.checked)
                  for (const audience of _audiences) {
                    audience.isCheck = e.target.checked
                  }
                  setAudiences(_audiences)
                }}></Checkbox></Th>
                {audienceDatas.map(audienceData => (
                  <Th>{audienceData.label}</Th>
                ))}
                <Th fontSize='md'>Tags</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {audiences.map((row: Audience, index) => {
                return (
                  <Tr key={row.email}>
                    <Td>
                      <Checkbox size="lg" colorScheme='red' isChecked={row.isCheck} onChange={(e) => {
                        const _audiences = [...audiences]
                        _audiences[index].isCheck = e.target.checked
                        setAudiences(_audiences)
                      }}></Checkbox>
                    </Td>
                    {audienceDatas.map(audienceData => (
                      <Th>{row.rawObject[row.dataMap[audienceData.id]]}</Th>
                    ))}
                    <Td>
                      {renderTags(row.tags)}
                    </Td>
                    <Td>
                      <IconButton
                        borderRadius="50px"
                        colorScheme='red'
                        aria-label='detail'
                        size='sm'
                        icon={<HiChevronRight />}
                      />
                    </Td>
                  </Tr>
                )}
              )}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </Box>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CSV Audience Import</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th minW="300px" >Column</Th>
                  <Th minW="200px" >Data</Th>
                </Tr>
              </Thead>
              <Tbody>
              {importedColumns.map((row, index) => {
                return (
                  <Tr key={`import-colum-${index}`}>
                    <Td>
                        { row }
                    </Td>
                    <Td>
                    <Select placeholder='Meta data' onChange={(e) => {
                      console.log(e.target.value)
                      const _selectedImportAudienceDatas = [...selectedImportAudienceDatas]
                      _selectedImportAudienceDatas[index] = e.target.value
                      setSelectedImportAudienceDatas(_selectedImportAudienceDatas)
                    }}>
                      {audienceDatas.map((row, index) => {
                        return (
                          <option disabled={selectedImportAudienceDatas.includes(row.id)} value={row.id}>
                            { row.label }
                          </option>
                        )
                      })}
                    </Select>
                    </Td>
                  </Tr>
                )}
              )}
              </Tbody>
            </Table>
            <Text mt='2px' fontSize='xl'>Tags:</Text>
            <ReactTags
              tags={tags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={beginImport}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default CampaignTables;
