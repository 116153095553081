import React, { ReactNode, useState, useEffect } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Button,
  VStack,
  HStack
} from '@chakra-ui/react';
import {
  FiMenu,
  FiLogOut
} from 'react-icons/fi';
import {
  HiUserGroup,
  HiMailOpen
} from 'react-icons/hi'
import { MdCampaign } from "react-icons/md";
import CampaignPage from './Page/CampaignPage';
import SendMailPage from './Page/SendMailPage';
import { getAuth, signOut } from "firebase/auth";

enum Menu {
  Audiences = 1,
  Campagins
}

const LinkItems = [
  { name: 'Audiences', icon: HiUserGroup, menu: Menu.Audiences },
  { name: 'Send Bulk Emails', icon: HiMailOpen, menu: Menu.Campagins },
];

export default function AdminHome() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menu, setMenu] = useState<Menu>(Menu.Audiences);
  const [user, setUser] = useState<any>(null)
  const menuChange = (selectedMenu: Menu) => {
    setMenu(selectedMenu)
  }
  const renderPages = () => {
    switch(menu) {
      case Menu.Audiences:   return <CampaignPage />;
      case Menu.Campagins:   return <SendMailPage />;

      default: return <CampaignPage />;
    }
  }
  const checkAuth = async () => {
    const auth = getAuth();
    auth.onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log(user.email)
        setUser(user)
      } else {
        window.location.href = '/admin-login'
      }
    });
  }

  const signOutFirebase = async () => {
    await signOut(getAuth())
    window.location.href = '/admin-login'
  }

  useEffect(() => {
    checkAuth()
  }, []);
  // linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%)
  // linear-gradient(to right, #ffe9e1 0%, #f9daff 100%)
  return (
    <Box minH="100vh" bg={useColorModeValue('linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%)', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }} onMenuChange={menuChange}      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onMenuChange={menuChange} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <VStack  align='end'>
          <Box w='120px' backgroundColor='#ffffff80' p='2' borderRadius='lg' boxShadow='lg'>
            <VStack>
              {/* <Text>Welcome, {user?.email}</Text> */}
              <Button onClick={signOutFirebase} leftIcon={<FiLogOut/>} variant='ghost'>Logout</Button>
            </VStack>
          </Box>
        </VStack>
        
        { renderPages() }
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, onMenuChange, ...rest }: any) => {
  return (
    <Box
      mt="5"
      mb="5"
      pb="5"
      bg={useColorModeValue('#ffffff80', 'gray.900')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      borderRadius="0px 30px 30px 0px"
      boxShadow='sm'
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
        Dashboard
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={() => {
          onMenuChange(link.menu)
        }}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }: any) => {
  return (
    <Link href="#" style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'red.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="25"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }: any) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Dashboard
      </Text>
    </Flex>
  );
};