import React, { ReactNode, useEffect, useState } from "react";
import { useTrail, a, useSpring, config, animated } from "@react-spring/web";
import {
  useDisclosure,
  Flex,
  Textarea,
  Text,
  Input,
  Button,
  Box,
  HStack,
  InputGroup,
  InputLeftAddon,
  VStack,
  Tag,
  useToast,
  Link,
  Divider,
  Switch,
  FormControl,
  FormLabel,
  Image,
  useClipboard,
} from "@chakra-ui/react";
import "@fontsource/nunito";
import Select from "react-select";
import { FiMenu, FiSend, FiLogOut } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import {
  MdNavigateNext,
  MdOutlineFileUpload,
  MdNavigateBefore,
  MdContentCopy,
  MdDone,
} from "react-icons/md";
import { MdCampaign } from "react-icons/md";
import {
  clientLoad,
  login,
  logout,
  listLabels,
  sendEmail,
  getCurrentUser,
  requestSendEmailScope,
  isSendEmailScope,
  SCOPES,
  getEmail,
} from "../Module/gapi";
import { AudienceUsecase } from "../Usecase/AudienceUsecase";
import moment from "moment";
import "../static/input-tag.css";
import "react-quill/dist/quill.snow.css";
import { Audience, AudienceData, AudienceTag } from "../Entity";
import { CKEditor } from "ckeditor4-react";
import { Wizard, useWizard } from "react-use-wizard";
import { MailListUsecase } from "../Usecase/MailListUsecase";
import { replaceLink } from "../Usecase/MailTrackUsecase";
import CommonAlertDialog from "../Components/Commons/CommonAlertDialog";
import { MailListItem } from "../Entity/MailListItem";
import ConfirmDialog from "../Components/Commons/ConfirmDialog";
import WhatsCSVModal from "../Components/Commons/WhatsCSVModal";
import ReplaceTextModal from "../Components/Commons/ReplaceTextModal";
import BybassVerifyModal from "../Components/Commons/BybassVerifyModal";
import {
  GoogleLogin,
  useGoogleLogin,
  hasGrantedAllScopesGoogle,
} from "@react-oauth/google";
const Papa = require("papaparse");

const ExpectAction = {
  Init: "init",
  SendMail: "sendmail",
  Login: "login",
};

export default function OpenSendMailPage() {
  const [mailFrom, setMailFrom] = useState("");
  const [mailTitle, setMailTitle] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expectAction, setExpectAction] = useState(ExpectAction.Init);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<AudienceTag[]>([]);
  const [currentGmailUser, setCurrentGmailUser] = useState<any>(null);
  const [emailColumnName, setEmailColumnName] = useState<SelectOption | null>(
    null
  );
  const gapi = window.gapi;
  const audienceUsecase = new AudienceUsecase();
  const mailListUsecase = new MailListUsecase();
  const alertDialogDisclosure = useDisclosure();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [receivers, setReceivers] = useState<MailListItem[]>([]);
  const [session, setSession] = useState<string>(
    new Date().getTime().toString()
  );
  const [isTrack, setIsTrack] = useState(false);
  const [isTrackLink, setIsTrackLink] = useState(true);
  const [isSentMails, setIsSentMails] = useState(false);
  const [tokenResponse, setTokenResponse] = useState<any>(null);
  const [isRawHtml, setIsRawHtml] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (token) => {
      setTokenResponse(token);
    },
    scope: SCOPES,
  });

  // confirm
  const confirmDialogDisclosure = useDisclosure();
  const [confirmTitle, setConfirmTitle] = useState("Confirm");
  const [confirmContent, setConfirmContent] = useState("");

  const toast = useToast();

  // --- begin upload csv file helper

  // --- for upload csv support
  const [openSelectEmail, setOpenSelectEmail] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showColumn = useDisclosure();
  const [importedColumns, setImportedColumns] = useState<string[]>([]);
  const [audienceDatas, setAudienceDatas] = useState<AudienceData[]>([]);
  const sample = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  type SelectOption = {
    value: string;
    label: string;
  };
  const [csvColumnsOption, setCsvColumnsOption] = useState<SelectOption[]>(
    sample
  );
  const [
    selectedImportAudienceDatas,
    setSelectedImportAudienceDatas,
  ] = useState<(string | null)[]>([]);
  const [csvData, setCsvData] = useState<any>(null);
  const input = document.createElement("input");
  let uploadingItemId = undefined;
  const loadFile = (e: any) => {
    input.type = "file";
    input.accept = ".csv";
    input.addEventListener("change", handleChangeFile);
    input.click();
  };

  const handleChangeFile = (evt: any) => {
    setIsLoading(true);
    var file = evt.target.files[0];
    let fileExtension = getFileExtension(file.name);

    if (!fileExtension || fileExtension[0].toString().localeCompare("csv")) {
      alert("CSVファイルを選択ください");
      return;
    }
    Papa.parse(file, {
      complete: async function (results: any, file: any) {
        try {
          console.log(results);
          console.log(results.data[0][results.meta.fields[0]]);
          setSelectedImportAudienceDatas(
            results.meta.fields.map((a: string) => null)
          );
          setImportedColumns(results.meta.fields);
          setCsvData(results);
          setOpenSelectEmail(true);
          setCsvColumnsOption(
            results.meta.fields.map((a: any) => {
              if (a.toLowerCase().includes("email")) {
                setEmailColumnName({ value: a, label: a });
              }
              return { value: a, label: a };
            })
          );
          showColumn.onOpen();
          // await batchImportCodes(uploadingItemId, results.data)
        } catch (error) {
          setIsLoading(false);
          alert(`エラーが発生しました：${error}`);
          return;
        }
        uploadingItemId = undefined;
        setIsLoading(false);
      },
      header: true,
    });
  };

  const getFileExtension = (
    filename: string
  ): RegExpExecArray | null | undefined => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  };

  function updateSigninStatus(isSignedIn: boolean) {
    if (isSignedIn) {
      console.log("gapi: signed in expect:", expectAction);
      setCurrentGmailUser(getCurrentUser());

      // listLabels()
      // sendEmail()
    } else {
      setCurrentGmailUser(null);
      console.log("gapi: signed out");
    }
  }

  // --- end of upload file helper
  useEffect(() => {
    const initData = async () => {
      const tags = await audienceUsecase.getTags();
      console.log(tags);
      setSuggestions(tags);
    };
    clientLoad(updateSigninStatus);
    initData();
    setMailTitle(localStorage.getItem("mailTitle") ?? "");
    setMailContent(localStorage.getItem("mailContent") ?? "");
    setMailFrom(localStorage.getItem("mailFrom") ?? "");
  }, []);

  // handle send target tags
  // const suggestions: any[] = [{id: 'nice', text: 'nice'}, {id: 'nice2', text: 'nice2'}]

  const showAlert = (title: string, content: string) => {
    setAlertTitle(title);
    setAlertContent(content);
    alertDialogDisclosure.onOpen();
  };

  const Boop = ({ mag = 0, timing = 150, children }: any) => {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
      margin: "20px auto",
      display: "inline-flex",
      transform: isBooped ? `translate(0px, -${mag}px)` : `translate(0px, 0px)`,
      config: {
        tension: 300,
        friction: 7,
      },
    });
    React.useEffect(() => {
      // Unchanged
      setTimeout(function () {
        setIsBooped(false);
      }, timing);
    }, [isBooped, timing]);
    const trigger = () => {
      // Unchanged
      setIsBooped(true);
    };
    return (
      <animated.div onMouseEnter={trigger} style={style}>
        {children}
      </animated.div>
    );
  };

  const Step1 = () => {
    const whatsCSVDisclosure = useDisclosure();
    const replaceTextDisclosure = useDisclosure();
    const [receiversText, setReceiversText] = useState<string>(
      localStorage.getItem("receivers") ?? ""
    );
    const [isTrackStep1, setIsTrackStep1] = useState(isTrack);
    const selectFadeIn = useSpring({
      to: { opacity: showColumn.isOpen ? 1 : 0 },
      from: { opacity: emailColumnName ? 1 : 0 },
    });
    const { handleStep, previousStep, nextStep } = useWizard();
    // Attach an optional handler
    handleStep(() => {
      const bgTask = async () => {
        const newSession = `${
          new Date("2199-12-17T03:24:00").getTime() - new Date().getTime()
        }-${uuidv4()}`;
        setSession(newSession);
        setIsTrack(isTrackStep1);
        const listReceivers: MailListItem[] = [];
        if (emailColumnName?.value) {
          const listData = await mailListUsecase.importMaillist(
            csvData.data,
            emailColumnName.value,
            newSession
          );
          listReceivers.push(...listData);
        } else if (receiversText !== "") {
          const listData = await mailListUsecase.importMailListText(
            receiversText,
            newSession
          );
          listReceivers.push(...listData);
        }
        setReceivers(listReceivers);
      };
      bgTask();
    });

    return (
      <>
        <Flex direction="column">
          <Text fontSize="2xl" fontWeight="bold" align="center" m="30px">
            Step 1: Setup your mail receivers
          </Text>
          <Flex direction="row" justifyContent="space-around">
            <VStack flex={1}>
              <Text fontSize="xl" fontWeight="bold">
                By paste your email list
              </Text>

              <Textarea
                disabled={emailColumnName?.value !== undefined}
                value={receiversText}
                onChange={(e) => {
                  localStorage.setItem("receivers", e.target.value);
                  setReceiversText(e.target.value);
                }}
                h="300px"
                placeholder={`email1@sample.com
email2@sample.com`}
              />
              <Text textColor="gray.500">
                Note: Each mail address should be in a new lines
              </Text>
            </VStack>
            <VStack alignSelf="center">
              <Divider ml="30px" mr="30px" orientation="vertical" h="100px" />
              <Text fontSize="md" fontWeight="bold">
                OR
              </Text>
              <Divider ml="30px" mr="30px" orientation="vertical" h="100px" />
            </VStack>

            <VStack flex={1}>
              <Text fontSize="xl" fontWeight="bold">
                By upload the CSV file
              </Text>
              <Link
                textDecoration="underline"
                onClick={() => {
                  whatsCSVDisclosure.onOpen();
                }}
              >
                What's CSV ?
              </Link>
              <Text align="center" fontSize="md" textColor="gray.500">
                By upload CSV File, you can also{" "}
                <Link
                  textDecoration="underline"
                  onClick={() => {
                    replaceTextDisclosure.onOpen();
                  }}
                >
                  replace text
                </Link>{" "}
                within mail content for each receiver.
              </Text>
              <Boop mag={5} timing={200}>
                <Button
                  variant="gradientStyle"
                  color="#fff"
                  fontSize="2xl"
                  leftIcon={<MdOutlineFileUpload />}
                  onClick={loadFile}
                  minH="60px"
                  boxShadow="md"
                >
                  Select file
                </Button>
              </Boop>
              <animated.div style={selectFadeIn}>
                <Flex direction="column">
                  <Text fontSize="xl">
                    Please select Email Column from your CSV:{" "}
                  </Text>
                  <Select
                    value={emailColumnName}
                    onChange={(v, e) => {
                      setEmailColumnName(v);
                    }}
                    options={csvColumnsOption}
                  />
                </Flex>
              </animated.div>
            </VStack>
          </Flex>
          <WhatsCSVModal
            isOpen={whatsCSVDisclosure.isOpen}
            onClose={() => {
              whatsCSVDisclosure.onClose();
            }}
          />
          <ReplaceTextModal
            isOpen={replaceTextDisclosure.isOpen}
            onClose={() => {
              replaceTextDisclosure.onClose();
            }}
          />
          <Flex alignSelf="center" mt="5">
            <FormControl display="flex">
              <FormLabel fontWeight="bold" htmlFor="email-alerts" mb="0">
                Mail tracking
              </FormLabel>
              <Switch
                onChange={(e) => {
                  setIsTrackStep1(e.target.checked);
                }}
                colorScheme="orange"
                isChecked={isTrackStep1}
                id="email-alerts"
              />
            </FormControl>
          </Flex>
          <HStack mt="10" justifyContent="space-evenly">
            <Button
              visibility="hidden"
              minW="160px"
              isDisabled
              onClick={() => previousStep()}
              borderRadius="30px"
              variant="gradientStyleOrange"
              leftIcon={<MdNavigateBefore />}
            >
              Previous Step
            </Button>
            <Button
              minW="160px"
              isDisabled={!emailColumnName && receiversText === ""}
              onClick={() => nextStep()}
              borderRadius="30px"
              variant="gradientStyleOrange"
              rightIcon={<MdNavigateNext />}
            >
              Next Step
            </Button>
          </HStack>
        </Flex>
      </>
    );
  };

  const Step2 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();
    const bybassVerifyModal = useDisclosure();
    const [mailFrom2, setMailFrom2] = useState(
      localStorage.getItem("mailFrom") ?? ""
    );
    const [mailto2, setMailTo2] = useState("");
    const [mailTitle2, setMailTitle2] = useState(
      localStorage.getItem("mailTitle") ?? ""
    );
    const [testMailAddress2, setTestMailAddress2] = useState(
      localStorage.getItem("testMailAddress") ?? ""
    );
    const [mailContent2, setMailContent2] = useState(
      localStorage.getItem("mailContent") ?? ""
    );

    const hasAccessScope = hasGrantedAllScopesGoogle(tokenResponse, SCOPES);
    // Attach an optional handler
    handleStep(() => {
      setMailFrom(mailFrom2);
      setMailTitle(mailTitle2);
      setMailContent(mailContent2);

      console.log(mailContent2);

      mailListUsecase.updateMailInfo(
        session,
        mailTitle2,
        mailContent2,
        mailFrom2
      );
    });

    const performSendTestMail = async () => {
      if (!checkInput()) {
        return;
      }

      if (testMailAddress2 === "") {
        showAlert("Error", "Please input test email receiver address");
        return false;
      }

      try {
        const realEmail = await getEmail(tokenResponse);

        const response = await sendEmail(
          tokenResponse,
          realEmail,
          `${mailFrom2}`,
          testMailAddress2,
          mailTitle2,
          mailContent2
        );
        if (response.status === 200) {
          toast({
            title: "Infomation",
            description: `Successfully send mail to ${testMailAddress2}。`,
            status: "info",
            duration: 8000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Notice",
            description: `Failed to send mail to ${testMailAddress2}。`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Notice",
          description: `Failed to send mail to ${testMailAddress2}。`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
      }
    };

    const checkInput = (): boolean => {
      if (!hasAccessScope) {
        showAlert("Error", "Please Linking your Google Account as a sender");
        return false;
      }

      if (mailTitle2 === "" || mailContent2 === "") {
        showAlert("Error", "Please input mail title / mail content");
        return false;
      }
      return true;
    };

    const [copyAlias, setCopyAlias] = useState("");

    const { hasCopied, onCopy } = useClipboard(copyAlias);

    React.useEffect(() => {
      if (copyAlias !== "") {
        onCopy();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyAlias]);

    return (
      <>
        <Box backgroundColor="#ffffff90" padding="20px" borderRadius="20px">
          <Text fontSize="2xl" fontWeight="bold" mb="5">
            Step 2: Prepare mail template
          </Text>
          <BybassVerifyModal
            isOpen={bybassVerifyModal.isOpen}
            onClose={() => {
              bybassVerifyModal.onClose();
            }}
          />
          <InputGroup>
            <InputLeftAddon minW="110px" children="Mail from" />
            <Input
              backgroundColor="white"
              variant="outline"
              focusBorderColor="red.400"
              placeholder="Mail from"
              value={mailFrom2}
              onChange={(e) => {
                localStorage.setItem("mailFrom", e.target.value);
                setMailFrom2(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup mt="5">
            <InputLeftAddon minW="110px" children="Mail title " />
            <Input
              backgroundColor="white"
              variant="outline"
              focusBorderColor="red.400"
              placeholder="Mail title"
              value={mailTitle2}
              onChange={(e) => {
                localStorage.setItem("mailTitle", e.target.value);
                setMailTitle2(e.target.value);
              }}
            />
          </InputGroup>
          <Text
            visibility={csvData?.meta?.fields ? "visible" : "hidden"}
            mt="2"
          >
            Use can use these tag-code to replace mail content with information
            in your CSV (UTF-8 character might be escape):
          </Text>
          <HStack>
            {(csvData?.meta?.fields ?? []).map((field: any) => (
              <Button
                rightIcon={
                  hasCopied &&
                  copyAlias === `:${encodeURIComponent(field)}:` ? (
                    <MdDone />
                  ) : (
                    <MdContentCopy />
                  )
                }
                colorScheme="facebook"
                variant="gradientStyleOrange"
                borderRadius="full"
                h="30px"
                onClick={() => {
                  setCopyAlias(`:${encodeURIComponent(field)}:`);
                }}
              >
                :{field}:
              </Button>
            ))}
          </HStack>
          {/* <Text>Mail From</Text>
        <Input value={mailFrom} onChange={(e) => {
          localStorage.setItem("mailFrom", e.target.value);
          setMailFrom(e.target.value)
        }} placeholder='Mail From'></Input>
        <Text>Mail Title</Text>
        <Input value={mailTitle} onChange={(e) => {
          localStorage.setItem("mailTitle", e.target.value);
          setMailTitle(e.target.value)
        }} placeholder='Mail Title'></Input> */}
          <Flex alignSelf="center" mt="5">
            <FormControl display="flex">
              <FormLabel fontWeight="bold" htmlFor="email-alerts" mb="0">
                Use RAW HTML:
              </FormLabel>
              <Switch
                onChange={(e) => {
                  setIsRawHtml(e.target.checked);
                }}
                colorScheme="orange"
                isChecked={isRawHtml}
                id="email-alerts"
              />
            </FormControl>
          </Flex>
          {!isRawHtml && (
            <div
              style={{
                backgroundColor: "#ffffff",
                minHeight: "6em",
                cursor: "text",
                marginTop: "10px",
              }}
            >
              {/* <Editor
              content={mailContent2}
              onChange={(value: string) => {
                localStorage.setItem("mailContent", value);
                setMailContent2(value);
              }}
            /> */}
              <CKEditor
                config={{
                  height: "500",
                }}
                style={{}}
                initData={localStorage.getItem("mailContent")}
                onChange={(e) => {
                  localStorage.setItem("mailContent", e.editor.getData());
                  setMailContent2(e.editor.getData());
                }}
              />
            </div>
          )}
          {isRawHtml && (
            <Textarea
              value={mailContent2}
              onChange={(e) => {
                setMailContent2(e.target.value);
              }}
              h="300px"
            />
          )}
          <VStack mt="5" align="left">
            {/* <HStack>
              <Text>Sending Account</Text>
              <Tag colorScheme="red">
                {currentGmailUser?.getEmail() ?? "Not linking email yet"}
              </Tag>
              <Button
                display={!currentGmailUser ? "none" : "initial"}
                variant="ghost"
                maxW="130px"
                colorScheme="red"
                fontSize="12px"
                leftIcon={<FiLogOut />}
                onClick={() => {
                  setExpectAction(ExpectAction.Login);
                  logout();
                }}
              >
                Logout
              </Button>
            </HStack> */}
            <Image
              src="/static/images/btn_google.png"
              display={tokenResponse ? "none" : "initial"}
              maxW="200px"
              onClick={() => {
                setExpectAction(ExpectAction.Login);
                googleLogin();
              }}
              cursor="pointer"
              // _hover={(src = "/static/images/btn_google_focus.png")}
            />
            <Link onClick={bybassVerifyModal.onOpen}>
              Encountered <b>Google hasn’t verified this app</b> error ?
            </Link>
            {tokenResponse && !hasAccessScope && (
              <VStack mt="20px" alignItems="flex-start">
                <Text size="xs" color="gray.500">
                  We also need the <b>Send email on your behalf</b> permission
                  in order to send email(s)
                </Text>
                <Text size="xs" color="orange.500">
                  Please click on <b>Grant Permission</b> button to grant
                  permission and continue
                </Text>
                <Button
                  onClick={() => {
                    googleLogin();
                    // requestSendEmailScope().then(
                    //   function (success) {
                    //     setCurrentGmailUser(getCurrentUser());
                    //   },
                    //   function (fail) {
                    //     console.log(
                    //       JSON.stringify({ message: "fail", value: fail })
                    //     );
                    //   }
                    // );
                  }}
                  variant="gradientStyle"
                >
                  Grant Permission
                </Button>
              </VStack>
            )}
            <VStack visibility={!hasAccessScope ? "collapse" : "visible"}>
              <InputGroup mt="5">
                <InputLeftAddon minW="110px" children="Test mail address " />
                <Input
                  backgroundColor="white"
                  variant="outline"
                  focusBorderColor="red.400"
                  placeholder="Ex: abc@example.com"
                  value={testMailAddress2}
                  onChange={(e) => {
                    localStorage.setItem("testMailAddress", e.target.value);
                    setTestMailAddress2(e.target.value);
                  }}
                />
                <Button
                  leftIcon={<FiSend />}
                  variant="ghost"
                  onClick={performSendTestMail}
                ></Button>
              </InputGroup>
              <Text size="xs" color="gray.400">
                * This will send a test mail to the above address so you can
                check it beforehand
              </Text>
            </VStack>
            {/* <Button
              variant="outline"
              maxW="250px"
              colorScheme="red"
              leftIcon={<FaGoogle />}
              onClick={() => {
                console.log(mailContent);
              }}
            >
              get html code
            </Button> */}
          </VStack>
          <HStack mt="10" justifyContent="space-evenly">
            <Button
              minW="160px"
              onClick={() => previousStep()}
              borderRadius="30px"
              variant="gradientStyleOrange"
              leftIcon={<MdNavigateBefore />}
            >
              Previous Step
            </Button>
            <Button
              minW="160px"
              isDisabled={!hasAccessScope}
              onClick={() => {
                if (!checkInput()) {
                  return;
                }
                nextStep();
              }}
              borderRadius="30px"
              variant="gradientStyleOrange"
              rightIcon={<MdNavigateNext />}
            >
              Next Step
            </Button>
          </HStack>

          {/* <br />
          <Button
            colorScheme="red"
            leftIcon={<FiSend />}
            onClick={() => {
              // setExpectAction(ExpectAction.SendMail)
              // login()
              // clientLoad(updateSigninStatus)
              performSendMail();
            }}
          >
            Send Bulk Mail
          </Button> */}
        </Box>
      </>
    );
  };
  const [sendMailLog, setSendMailLog] = useState<string[]>([]);
  const Step3 = () => {
    const selectFadeIn = useSpring({
      to: { opacity: showColumn.isOpen ? 1 : 0 },
      from: { opacity: emailColumnName ? 1 : 0 },
    });
    const { handleStep, previousStep, nextStep } = useWizard();
    // Attach an optional handler
    handleStep(() => {});

    const performSendMail = async () => {
      let rpMailContent = mailContent;

      const fieldList = csvData?.meta?.fields;
      let logData = [...sendMailLog];

      const realEmail = await getEmail(tokenResponse);

      for (const audience of receivers) {
        rpMailContent = mailContent;
        if (isTrack) {
          rpMailContent = replaceLink(rpMailContent, audience.callBackId);
          rpMailContent = `${rpMailContent}<div hspace="streak-pt-mark" style="max-height:1px">
          <img style="width=0ox; max-height=1px; overflow: hidden" src="https://us-central1-english-newsy.cloudfunctions.net/mailSign?callbackId=${audience.callBackId}" />
          <font color="#ffffff" size="1">ᐧ</font>
        </div>`;
        }
        console.log("audience:", audience);
        // eslint-disable-next-line no-loop-func
        if (fieldList) {
          for (const field of fieldList) {
            rpMailContent = rpMailContent.replaceAll(
              `:${encodeURIComponent(field)}:`,
              audience.data[field] ?? ""
            );
          }
        }

        try {
          const result = await sendEmail(
            tokenResponse,
            realEmail,
            `${mailFrom}`,
            audience.email,
            mailTitle,
            `${rpMailContent}`
          );

          mailListUsecase.addCallback(audience.callBackId, audience.email);

          if (result.status === 200) {
            logData.push(
              `[${moment().format("yyyy/MM/DD HH:mm:ss")}] Sent mail to: ${
                audience.email
              }`
            );
          } else {
            logData.push(
              `[${moment().format(
                "yyyy/MM/DD HH:mm:ss"
              )}][ERROR] Failed to sent mail to: ${audience.email}`
            );
          }
        } catch (error) {
          console.log(error);
          logData.push(
            `[${moment().format(
              "yyyy/MM/DD HH:mm:ss"
            )}][ERROR] Failed to sent mail to: ${audience.email}`
          );
        }

        console.log(logData);
        setSendMailLog([...logData]);
      }
      setIsSentMails(true);
    };

    const confirmSendMail = () => {
      // if (!currentGmailUser) {
      //   showAlert("Error", "Please Linking your Google Account as a sender");
      //   return;
      // }

      if (mailTitle === "" || mailContent === "") {
        showAlert("Error", "Please input mail Title & Content");
        return;
      }

      setConfirmContent(
        `Mail will be sent to all target mail list. Are you ready?`
      );
      confirmDialogDisclosure.onOpen();
    };

    const [trackMailLink, setTrackMailLink] = useState(
      `https://beta.mailalpha.app/mail-track?query=${session}`
    );
    const { hasCopied, onCopy } = useClipboard(trackMailLink);

    return (
      <>
        <Flex direction="column">
          <Text fontSize="2xl" fontWeight="bold">
            Step 3: You're now ready to send your bulk emails
          </Text>
          <Boop mag={5} timing={200}>
            <Button
              variant="gradientStyle"
              color="#fff"
              fontSize="2xl"
              leftIcon={<FiSend />}
              onClick={() => {
                confirmSendMail();
              }}
              minH="60px"
              boxShadow="md"
            >
              Send Bulk Email
            </Button>
          </Boop>
          <Box
            mt="5"
            bg="gray.200"
            h="30vh"
            borderRadius="10px"
            boxShadow="inner"
            textOverflow="scroll-y"
            overflowY="scroll"
            pl="6"
            pr="6"
            pt="2"
            pb="5"
            fontSize="sm"
            fontFamily="monospace"
          >
            {sendMailLog
              .slice()
              .reverse()
              .map((log) => (
                <Text key={log}>
                  {log}
                  <br></br>
                </Text>
              ))}
          </Box>
          {isTrack && isSentMails && (
            <VStack mt="2" w="100%">
              <Text>You can track mail viewed status at:</Text>
              <Flex mt={2} w="100%">
                <Input value={trackMailLink} isReadOnly />
                <Button onClick={onCopy} ml={2}>
                  {hasCopied ? "Copied" : "Copy"}
                </Button>
              </Flex>
              <Text>
                Tip: You can also save this link somewhere and check the mails
                status later
              </Text>
            </VStack>
          )}

          <HStack mt="10" justifyContent="space-evenly">
            <Button
              minW="160px"
              onClick={() => previousStep()}
              borderRadius="30px"
              variant="gradientStyleOrange"
              leftIcon={<MdNavigateBefore />}
            >
              Previous Step
            </Button>
            <Button
              minW="160px"
              isDisabled
              visibility="hidden"
              onClick={() => nextStep()}
              borderRadius="30px"
              variant="gradientStyleOrange"
              rightIcon={<MdNavigateNext />}
            >
              Next Step
            </Button>
          </HStack>
          <ConfirmDialog
            isOpen={confirmDialogDisclosure.isOpen}
            onClose={confirmDialogDisclosure.onClose}
            title={confirmTitle}
            content={confirmContent}
            onConfirm={() => {
              performSendMail();
            }}
          />
        </Flex>
      </>
    );
  };

  return (
    <Flex m="5" direction="column" minH="95vh">
      <Text
        w="fit-content"
        bgGradient="linear(to-l, #fe7582, #6f5ee0)"
        bgClip="text"
        fontSize="4xl"
        fontWeight="extrabold"
      >
        Mail @lpha
      </Text>
      <Text
        w="fit-content"
        fontSize="50px"
        lineHeight="1.2"
        color="#853773"
        fontWeight="800"
        bgGradient="linear(to-l, #ffa34b, #ff919e)"
        bgClip="text"
      >
        Send bulk mails
      </Text>
      <div style={{ display: "none" }}>
        <CKEditor
          config={{
            height: "500",
          }}
          style={{}}
        />
      </div>
      <Box flexGrow="2" w="fit-content" m="auto">
        <Wizard>
          <Step1 />
          <Step2 />
          <Step3 />
        </Wizard>
      </Box>
      <Text
        bgGradient="linear(to-l, #fe7582, #6f5ee0)"
        bgClip="text"
        fontSize="sm"
        mb="2"
        ml="2"
        mr="2"
        fontWeight="extrabold"
        maxW="200px"
        alignSelf="center"
        w="fit-content"
      >
        Made with ♥️ from Japan
      </Text>
      <CommonAlertDialog
        isOpen={alertDialogDisclosure.isOpen}
        onClose={alertDialogDisclosure.onClose}
        title={alertTitle}
        content={alertContent}
      />
    </Flex>
  );
}
