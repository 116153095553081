import { Audience, AudienceData, AudienceTag } from "../Entity";
import { db } from "../firebase";
import {
  connectFirestoreEmulator,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  Timestamp,
  deleteDoc,
  runTransaction,
  query,
  where,
  startAt,
  limit,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { MailListItem } from "../Entity/MailListItem";

export type TrackEmail = {
  email: string;
  count: number;
  firstOpen?: Date;
  isOpenedLink: boolean;
  lastOpenLink?: Date;
};

export class MailTrackUsecase {
  team: string = "openAll";
  async GetTrackedMails(session: string): Promise<TrackEmail[]> {
    const collectionSnapshot = await getDocs(
      query(collection(db, "teams", this.team, "sessions", session, "datas"))
    );
    const sessionDatas: any[] = [];
    collectionSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      sessionDatas.push(doc);
    });
    const result: TrackEmail[] = [];
    for (const sessionData of sessionDatas) {
      const data = sessionData.data();
      // const callbacksSnapshot = await getDocs(
      //   collection(db, "callbacks", data.callBackId, "logs")
      // );
      // const callbackList: any[] = [];
      // callbacksSnapshot.forEach((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   callbackList.push(doc.data());
      // });

      const callbackDoc = await getDoc(doc(db, "callbacks", data.callBackId));

      result.push({
        email: sessionData.data().email,
        count: (callbackDoc.data() as any)?.called ?? 0,
        firstOpen: (callbackDoc.data() as any)?.lastCalled?.toDate(),
        isOpenedLink: (callbackDoc.data() as any)?.redirected ?? 0,
        lastOpenLink: (callbackDoc.data() as any)?.lastRedirected?.toDate(),
      });
    }
    return result;
  }
}

export function replaceLink(data: string, callbackId: string): string {
  const links = data.match(/href="([^"]*)"/g);
  var newData = data;
  for (const link of links ?? []) {
    const urlPart = link.split('"')[1];
    const newLink = link.replaceAll(
      urlPart,
      `https://us-central1-english-newsy.cloudfunctions.net/redirect?url=${encodeURIComponent(
        urlPart
      )}&callbackId=${callbackId}`
    );
    newData = newData.replaceAll(link, newLink);
  }
  return newData;
}
