import {
  connectFirestoreEmulator,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  Timestamp,
  deleteDoc,
  runTransaction,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import moment from "moment";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAR6ezfllmijHUGke3RvXuhgvfcccuWEUo",
  authDomain: "english-newsy.firebaseapp.com",
  projectId: "english-newsy",
  storageBucket: "english-newsy.appspot.com",
  messagingSenderId: "561596304955",
  appId: "1:561596304955:web:754b4f537488322053d61e",
  measurementId: "G-D6V5GCK0GK",
};

const app = initializeApp(firebaseConfig);

// export const analytics = getAnalytics(app);

export const db = getFirestore(app);

export const auth = getAuth(app);
