// theme.js
import { extendTheme } from "@chakra-ui/react";

const gradientTheme = extendTheme({
  fonts: {
    heading: "Nunito, Noto Sans, sans-serif",
    body: "Nunito, Noto Sans, sans-serif",
  },
  components: {
    Button: {
      // 3. We can add a new visual variant
      variants: {
        gradientStyle: {
          color: "#fff",
          bg: "linear-gradient(135deg, #f093fb, #f5576c)",
          _hover: {
            bg: "linear-gradient(135deg, #f06dff, #fe344f)",
          },
          boxShadow: "sm",
        },
        gradientStyleOrange: {
          color: "#fff",
          bg: "linear-gradient(135deg, #ffa34b, #ff919e)",
          _hover: {
            bg: "linear-gradient(135deg, #ff9025, #ff688e)",
            boxShadow: "md",
          },
          border: "0px",
          boxShadow: "sm",
        },
        // 4. We can override existing variants
        solid: (props: any) => ({
          // bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        }),
      },
    },
  },
});

export default gradientTheme;
